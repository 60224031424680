<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h4 text--primary">Технологии</v-card-title>
      <v-card-text>
        <strong>ВНИМАНИЕ!</strong> В разделе «Технологии» указаны примерные параметры сканеров-дефектоскопов.
      </v-card-text>
      <v-card-text>
        За точными параметрами сканеров-дефектоскопов, их возможностями и особенностями применения просьба обращаться по адресу:
        <a href="mailto:info@introscan.ru">info@introscan.ru.</a>
      </v-card-text>
      <v-card-text>
        <v-carousel
          cycle
          height="70vh"
          show-arrows-on-hover
        >
          <!-- <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
            :src="slide.key"
          >
          </v-carousel-item> -->
          <v-carousel-item
            v-for="i in 17"
            :key="i-1"
            :src="require(`@/assets/slides/${i-1}.jpg`)"
            contain
          >
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// const slides = require.context('@/assets/slides/', true, /\.jpg$/);

export default {
  data() {
    return {
      // slides,
    };
  },
};
</script>
